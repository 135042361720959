import { IconButton, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { Invoice } from "./types";
import { getDateFormat, getTimeFormat } from "../../shared/helpers/timeFormat";
import { InvoiceStatusUI } from "./InvoiceStatus";
import { InvoiceDownloadButton } from "./InvoiceDownloadButton";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from "react-router-dom";

export function InvoicesTable({ invoices }: { invoices: Invoice[] }) {
    return (
        <>
            <InvoicesTableHeader />
            <InvoicesTableBody invoices={invoices} />
        </>
    )
}

function InvoicesTableHeader() {
    return (
        <TableHead>
            <TableRow>
                <TableCell sx={{ width: '100px' }}>Клиент</TableCell>
                <TableCell sx={{ width: '200px' }}>Начало</TableCell>
                <TableCell sx={{ width: '200px' }}>Конец</TableCell>
                <TableCell sx={{ width: '150px' }}>Статус</TableCell>
                <TableCell sx={{ width: '200px' }}>Дата формирования инвойса</TableCell>
                <TableCell sx={{ width: '150px' }}>Скачать</TableCell>
                <TableCell sx={{ width: '150px' }}>Посмотреть полностью</TableCell>
            </TableRow>
        </TableHead>
    )
}

function InvoicesTableBody({ invoices }: { invoices: Invoice[] }) {
    const navigate = useNavigate();

    return (
        <TableBody>
            {
                invoices.map((invoice) => {
                    const lastStatus = invoice.historyChanges?.at(-1)?.status;
                    return (
                        <TableRow key={invoice.id} hover={true}>
                            <TableCell>{invoice.clientCode}</TableCell>
                            <TableCell>{getTimeFormat(new Date(invoice.from))} {getDateFormat(new Date(invoice.from))}</TableCell>
                            <TableCell>{getTimeFormat(new Date(invoice.to))} {getDateFormat(new Date(invoice.to))}</TableCell>
                            <TableCell>{
                                invoice?.historyChanges?.at(-1)
                                    ? <InvoiceStatusUI status={invoice.historyChanges?.at(-1)!.status} />
                                    : <InvoiceStatusUI status='Unknown' />
                            }</TableCell>
                            <TableCell>
                                {
                                    invoice.createdAt
                                        ? `${getTimeFormat(new Date(invoice.createdAt))} ${getDateFormat(new Date(invoice.createdAt))}`
                                        : '--'
                                }
                            </TableCell>
                            <TableCell>
                                {lastStatus && ['Unknown', 'Created'].findIndex((value) => value === lastStatus) === -1
                                    && <InvoiceDownloadButton invoice={invoice} />}
                            </TableCell>
                            <TableCell>
                                <IconButton
                                    onClick={() => navigate(`/invoices/${invoice.id}`)}
                                >
                                    <ArrowForwardIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    )
                })
            }
        </TableBody>
    )
}