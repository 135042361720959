import { Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead } from "@mui/material";
import { DailyDealCovererIndicators, GetCoverReportResponse } from "./types";
import { useMemo } from "react";
import { CoverReportRow } from "./CoverReportRow";
import './styles.css';

interface CoverReportTableProps {
    date: string;
    isFetching: boolean;
    dailyDealCovererIndicatorsArray: GetCoverReportResponse['dailyDealCovererIndicatorsArray'];
}

export function CoverReportTable({
    date,
    isFetching,
    dailyDealCovererIndicatorsArray,
}: CoverReportTableProps) {
    const clientCodes = useMemo(() => {
        const allClientCodes = dailyDealCovererIndicatorsArray
            .map((value) => value.clientCode);
        const uniqueClientCodes = Array.from(new Set(allClientCodes));

        return uniqueClientCodes.sort();
    }, [dailyDealCovererIndicatorsArray]);

    const indicatorsPerInstrument = useMemo(() => {
        const map: Map<string, DailyDealCovererIndicators[]> = new Map();
        dailyDealCovererIndicatorsArray.forEach((indicator) => {
            if (!map.has(indicator.instrument)) {
                map.set(indicator.instrument, new Array<DailyDealCovererIndicators>());
            }

            const array = map.get(indicator.instrument) as DailyDealCovererIndicators[];
            array.push(indicator);
        });
        const result = Array.from(map.entries()).map((entry) => ({
            instrument: entry[0],
            indicators: entry[1],
        }))
        return result;
    }, [dailyDealCovererIndicatorsArray]);

    return (
        <TableContainer
            component={Paper}
            sx={{
                overflowX: 'auto'
            }}
        >
            {
                isFetching
                    ? (
                        <Skeleton
                            variant="rectangular"
                            height={300}
                            width={'100%'}
                        />
                    )
                    : (
                        <Table stickyHeader className={'coverReportTable'}>
                            <TableHead>
                                <TableCell sx={{ width: '300px' }}>
                                    Дата
                                </TableCell>

                                <TableCell sx={{ width: '300px' }}>
                                    Инструмент
                                </TableCell>

                                <TableCell sx={{ width: '300px' }}>
                                    Total
                                </TableCell>

                                {
                                    clientCodes.map((clientCode) => (
                                        <TableCell key={clientCode} align="center">
                                            {clientCode.toUpperCase()}
                                        </TableCell>
                                    ))
                                }
                            </TableHead>

                            <TableBody>
                                {
                                    indicatorsPerInstrument.map((item) => (
                                        <CoverReportRow
                                            date={date}
                                            clientCodes={clientCodes}
                                            instrument={item.instrument}
                                            indicators={item.indicators}
                                        />
                                    ))
                                }
                                <caption style={{ width: '100%' }}>
                                    Всего: {Array.from(indicatorsPerInstrument.entries()).length}
                                </caption>
                            </TableBody>
                        </Table>
                    )
            }
        </TableContainer>
    )
}