import { FocusEventHandler, useMemo, useState } from "react";
import { Autocomplete, AutocompleteProps, SxProps, TextField } from "@mui/material";

import { useFetchAllClients } from "../clientAPISlice";

interface ClientCodeInputProps extends Omit<AutocompleteProps<string, undefined, undefined, undefined>, 'options' | 'onChange' | 'renderInput'> {
    value: string | null | undefined;
    onChange: (value: string | null) => void;

    label?: string;
    error?: boolean;
    helperText?: string;
    name?: string;
    placeholder?: string;
    required?: boolean;
    onInputBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    inputStyle?: SxProps;
    inputSlotProps?: Parameters<typeof TextField>['0']['slotProps'];
}


export const ClientCodeInput = (props: ClientCodeInputProps) => {
    const { data, isFetching } = useFetchAllClients({});

    const clientOptions = useMemo(() => {
        if (!data) {
            return [];
        }
        return data.map(client => client.code);
    }, [data]);

    const [inputValue, setInputValue] = useState('');

    return (
        <Autocomplete
            options={clientOptions}
            loading={isFetching}
            {...props}
            getOptionLabel={option => option}

            value={props.value}
            onChange={(e, value) => props.onChange(value)}

            inputValue={inputValue}
            onInputChange={(e, newInputValue) => setInputValue(newInputValue.toUpperCase())}

            renderInput={(params) =>
                <TextField
                    {...params}
                    label={props?.label}
                    error={props?.error}
                    onBlur={props?.onInputBlur}
                    helperText={props?.helperText}
                    sx={{
                        minWidth: '150px',
                        ...(props.inputStyle ?? {})
                    }}
                    slotProps={props.inputSlotProps}
                />}
        />
    )
}