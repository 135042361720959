import { useFormik } from "formik";
import { ClientCodeInput } from "../client/ui/ClientInput";
import { getEndPreviousMonthDateString, getStartPreviousMonthDateString } from "./utils/utils";
import { useCreateInvoice } from "./invoiceApi.slice";
import { LoadingButton } from "@mui/lab";
import * as Yup from 'yup';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/ru.js';

const initialValues = {
    clientCode: '',
    from: getStartPreviousMonthDateString(),
    to: getEndPreviousMonthDateString(),
}

const validationSchema = Yup.object({
    clientCode: Yup.string().required(),
    from: Yup.string().required(),
    to: Yup.string().required(),
})

export function InvoiceCreateForm() {
    const [createInvoice, metadata] = useCreateInvoice();

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            console.log(values);
            if (values.clientCode) {
                createInvoice(values);
            }
        }
    })


    return (
        <form
            onSubmit={formik.handleSubmit}
            style={{
                marginBottom: '30px'
            }}
        >
            <div style={{ display: 'flex', alignItems: 'start', gap: '20px' }}>
                <ClientCodeInput
                    className="Input"
                    id="clientCode"
                    name="clientCode"
                    placeholder="Клиент"
                    label="Клиент"
                    required={true}
                    value={formik.values.clientCode}
                    onChange={(value: unknown) => {
                        formik.values.clientCode = value as string;
                    }}
                    onBlur={formik.handleBlur}
                    error={!!formik.touched.clientCode && !!formik.errors.clientCode}
                    helperText={formik.touched.clientCode ? formik.errors.clientCode : undefined}
                    style={{
                        margin: '8px 0',
                        paddingTop: '13px'
                    }}
                />

                <div className="deal-filter">
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
                        <div>
                            <DatePicker
                                className="Input"
                                name="dateTime"
                                label="Начало"
                                value={dayjs(formik.values.from ? new Date(formik.values.from) : new Date())}
                                onChange={newValue => {
                                    formik.handleChange({ target: { value: newValue?.toJSON(), id: 'from' } });
                                }}
                                disableFuture
                            />
                        </div>
                        <div>
                            <DatePicker
                                className="Input"
                                name="dateTime"
                                label="Конец"
                                value={dayjs(formik.values.to ? new Date(formik.values.to) : new Date())}
                                onChange={newValue => {
                                    formik.handleChange({ target: { value: newValue?.toJSON(), id: 'to' } });
                                }}
                            />
                        </div>
                    </LocalizationProvider>
                </div>

                <LoadingButton
                    variant="contained"
                    type="submit"
                    sx={{ height: '52px', marginTop: '22px' }}
                    loading={metadata.isLoading}
                >
                    Создать инвойс
                </LoadingButton>
            </div>
        </form>
    )
}