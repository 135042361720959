import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import { convertReportToText } from "./utils/convertReportToText";
import { GetDailyReportResponse } from "./types";


export function CopyAnalyticsButton({
    data,
}: { data?: GetDailyReportResponse }) {
    const [isLoading, setIsLoading] = useState(false);

    return (
        <LoadingButton
            variant="contained"
            startIcon={<ContentCopyIcon />}
            loading={isLoading}
            disabled={data === undefined}
            onClick={() => {
                if (!data) {
                    return;
                }
                
                setIsLoading(true);

                const text = convertReportToText(data);
                navigator.clipboard.writeText(text)
                    .finally(() => setIsLoading(false));
            }}
        >
            Копировать
        </LoadingButton>
    )
}