import { useEffect, useState } from "react";

import { Checkbox, FormControl, FormGroup, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import { LoadingButton } from "@mui/lab";
import { DatePicker, DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/ru.js';
import { NumericFormat } from "react-number-format";

import { useNavigate } from "react-router-dom";
import * as Yup from 'yup';
import { useFormik } from "formik";

import './DealForm.css';
import { ClientCodeInput } from "../../client/ui/ClientInput";
import { InstrumentInput } from "./DealInputs/InstrumentInput";
import { Error } from "../../../shared/ui/Error";
import { getNowTimeString } from "../../../shared/helpers/timeFormat";
import { ExchangeRate } from "../../CBRF/ExchangeRate";
import { CLEARINGS, Counterparty, Deal, FormDeal, Instrument } from "../deal.dto";
import { Metadata } from "../../../api/api.dto";


function getInitialValuesForEmptyDeal(): FormDeal {
    return {
        dealDateTime: getNowTimeString(),
        valueDateTimeBase: getNowTimeString().substring(0, 'YYYY-MM-DD'.length),
        valueDateTimeQuoted: getNowTimeString().substring(0, 'YYYY-MM-DD'.length),

        instrument: '',
        buyerCode: '',
        sellerCode: '',

        value: 0,
        rate: 0,
        counterparty: Counterparty.line
    };
}

function getInitialValuesForDeal(deal: Deal) {
    return {
        id: deal.id,

        dealDateTime: deal.dealDateTime.substring(0, 'YYYY-MM-DDTHH:MM'.length),
        valueDateTimeBase: deal.valueDateTimeBase.substring(0, 'YYYY-MM-DDTHH:MM'.length),
        valueDateTimeQuoted: deal.valueDateTimeQuoted.substring(0, 'YYYY-MM-DDTHH:MM'.length),

        instrument: deal.instrument,
        buyerCode: deal.buyerCode,
        sellerCode: deal.sellerCode,

        value: deal.value,
        rate: deal.rate,
        counterparty: deal.counterparty
    };
}

const validationSchema = Yup.object({
    dealDateTime: Yup.date()
        .required('Введите дату и время заключения сделки.'),
    valueDateTimeBase: Yup.date()
        .required('Введите дату для базовой валюты.'),
    valueDateTimeQuoted: Yup.date()
        .required('Введите дату для котируемой валюты.'),
    instrument: Yup.string()
        .required('Введите инструмент.'),
    buyerCode: Yup.string()
        .required('Введите код продавца.'),
    sellerCode: Yup.string()
        .required('Введите код покупателя.'),
    value: Yup.number()
        .required('Введите объем.')
        .positive('Введите положительное число.'),
    rate: Yup.number()
        .required('Введите курс.')
        .positive('Курс - положительное число.'),
    counterparty: Yup.string()
        .required('Введите клиринг.')
});

export const DealForm = ({
    deal,
    submitFunction,
    metadata
}: {
    deal: FormDeal | undefined,
    submitFunction: (deal: FormDeal) => any,
    metadata: Metadata
}) => {
    const [isBaseQuotedInOneDay, setIsBaseQuotedInOneDay] = useState(true);

    const formik = useFormik({
        initialValues: deal ? { ...deal } : getInitialValuesForEmptyDeal(),
        validationSchema,
        onSubmit: values => submitFunction(values),
    });

    const navigate = useNavigate();
    useEffect(() => {
        if (metadata.isSuccess) {
            navigate('/deals');
        }
    }, [metadata.isSuccess]);

    dayjs.locale('ru');

    return (
        <form className="DealForm" onSubmit={formik.handleSubmit}>
            <FormGroup row>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
                    <DateTimePicker
                        className="Input"
                        name="dateTime"
                        label="Дата заключения сделки"
                        value={dayjs(formik.values.dealDateTime)}
                        onChange={newValue => {
                            formik.values.dealDateTime = newValue?.toJSON() ?? '';
                        }}
                    />
                </LocalizationProvider>
            </FormGroup>

            <h3>Инструмент</h3>
            <Grid container rowSpacing={2} columnSpacing={2}>
                <Grid item xs={6}>
                    <InstrumentInput
                        className="Input"
                        id="instrument"
                        name="instrument"
                        placeholder="Инструмент"
                        label="Инструмент"
                        required
                        value={formik.values.instrument}
                        onChange={(event: React.SyntheticEvent<Element, Event>, value: unknown) => {
                            formik.values.instrument = value ? (value as Instrument).instrument : '';
                        }}
                        onBlur={formik.handleBlur}
                        error={!!formik.touched.instrument && !!formik.errors.instrument}
                        helperText={formik.touched.instrument ? formik.errors.instrument : null}
                        style={{
                            margin: '10px 0'
                        }}
                    />
                </Grid>
                <Grid item xs={6} sx={{ alignSelf: 'center' }} >
                    <ExchangeRate
                        currency={formik.values.instrument?.split('/')[0]}
                        dateTime={formik.values.dealDateTime}
                    />
                </Grid>

                <Grid item xs={6}>
                    <ClientCodeInput
                        className="Input"
                        id="buyerCode"
                        name="buyerCode"
                        placeholder="Купил"
                        label="Купил"
                        required={true}
                        value={formik.values.buyerCode}
                        onChange={(value: unknown) => {
                            formik.values.buyerCode = value as string;
                        }}
                        onBlur={formik.handleBlur}
                        error={!!formik.touched.buyerCode && !!formik.errors.buyerCode}
                        helperText={formik.touched.buyerCode ? formik.errors.buyerCode : undefined}
                        style={{
                            margin: '10px 0'
                        }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <ClientCodeInput
                        className="Input"
                        id="sellerCode"
                        name="sellerCode"
                        placeholder="Продал"
                        label="Продал"
                        required={true}
                        value={formik.values.sellerCode}
                        onChange={(value: unknown) => {
                            formik.values.sellerCode = value as string;
                        }}
                        onBlur={formik.handleBlur}
                        error={!!formik.touched.sellerCode && !!formik.errors.sellerCode}
                        helperText={formik.touched.sellerCode ? formik.errors.sellerCode : undefined}
                        style={{
                            margin: '10px 0'
                        }}
                        fullWidth
                    />
                </Grid>

                <Grid item xs={6}>
                    <NumericFormat
                        value={formik.values.value}
                        onValueChange={(values, sourceInfo) => {
                            formik.values.value = Number(values.value);
                        }}
                        allowNegative={false}
                        allowedDecimalSeparators={[',']}
                        thousandSeparator

                        id="value"
                        name="value"
                        placeholder="Объем"
                        label="Объем"
                        required
                        margin="normal"
                        fullWidth
                        onBlur={formik.handleBlur}
                        error={!!formik.touched.value && !!formik.errors.value}
                        helperText={formik.touched.value ? formik.errors.value : null}

                        customInput={TextField}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        className="Input"
                        type="number"
                        id="rate"
                        name="rate"
                        placeholder="Курс"
                        label="Курс"
                        required={true}
                        margin="normal"
                        fullWidth
                        value={formik.values.rate}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={!!formik.touched.rate && !!formik.errors.rate}
                        helperText={formik.touched.rate ? formik.errors.rate : null}
                    />
                </Grid>
            </Grid>

            <Grid container style={{
                margin: '40px 0'
            }}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
                    <Grid item xs={4}>
                        <DatePicker
                            className="Input"
                            name="valueDateBase"
                            label="Дата валют-ния"
                            value={dayjs(formik.values.valueDateTimeBase)}
                            onChange={newValue => {
                                formik.values.valueDateTimeBase = newValue?.format('YYYY-MM-DD') ?? '';
                                if (isBaseQuotedInOneDay) {
                                    formik.values.valueDateTimeQuoted = newValue?.format('YYYY-MM-DD') ?? '';
                                }
                            }}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <DatePicker
                            className="Input"
                            name="valueDateQuoted"
                            label="Дата валют-ния"
                            disabled={isBaseQuotedInOneDay}
                            value={dayjs(isBaseQuotedInOneDay
                                ? formik.values.valueDateTimeBase
                                : formik.values.valueDateTimeQuoted)}
                            onChange={newValue => {
                                formik.values.valueDateTimeQuoted = newValue?.format('YYYY-MM-DD') ?? '';
                            }}
                        />
                    </Grid>
                </LocalizationProvider>

                <Grid item xs={12}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <Checkbox
                            checked={isBaseQuotedInOneDay}
                            onChange={e => setIsBaseQuotedInOneDay(e.target.checked)}
                        />
                        <span>Даты валютирования равны?</span>
                    </div>
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <InputLabel>Клиринг</InputLabel>

                        <Select
                            className="Input"
                            id="counterparty"
                            name="counterparty"
                            label="Клиринг"
                            required={true}
                            value={formik.values.counterparty}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={!!formik.touched.counterparty && !!formik.errors.counterparty}
                        >
                            {
                                CLEARINGS.map(clearing => <MenuItem
                                    key={clearing}
                                    value={clearing}>
                                    {clearing}
                                </MenuItem>)
                            }
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>


            {metadata.error ? <Error message={`${metadata.error}`} /> : null}

            <LoadingButton
                type='submit'
                variant="contained"
                endIcon={<SendIcon />}
                sx={{
                    margin: '40px 0',
                }}
                loading={metadata.isLoading}
            >
                Подтвердить
            </LoadingButton>
        </form>
    )
}