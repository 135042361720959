import { Stack } from "@mui/material";

import { DailyAnalyticsTable } from "./DailyAnalyticsTable";
import { DailyTotal } from "./DailyTotal";
import { SelectDay } from "./SelectDay";
import { useState } from "react";
import { useGetDailyLightReport } from "./dailyAnalyticsApi.slice";
import { ReportCalculatedInfo } from "./ReportCalculatedInfo";
import { CopyAnalyticsButton } from "./CopyAnalyticsButton";


export function DailyAnalyticsWidget() {
    const [day, setDay] = useState(new Date().toJSON().split('T')[0]);

    const { currentData, isFetching, error } = useGetDailyLightReport(day);

    return (
        <Stack spacing={4} alignItems={'flex-start'}>

            <Stack direction='row' alignItems='center' spacing={5}>
                <SelectDay
                    value={day}
                    onChange={setDay}
                />

                <ReportCalculatedInfo
                    isLoading={isFetching}
                    calculatedAt={currentData?.calculatedAt}
                    error={error}
                />
            </Stack>

            <Stack direction='row' alignItems='center' spacing={5}>
                <DailyTotal
                    isLoading={isFetching}
                    commissionDailyAggregated={currentData?.commissionDailyAggregated}
                    commissionMonthlyAggregated={currentData?.commissionMonthlyAggregated}
                />

                <CopyAnalyticsButton data={currentData} />
            </Stack>

            <DailyAnalyticsTable
                isLoading={isFetching}
                dailyDomIndicatorsArray={currentData?.dailyDomIndicatorsArray ?? []}
            />
        </Stack>
    )
}