import { ErrorApi } from "../../shared/types";
import { Error } from "../../shared/ui/Error";
import { RequestError, RequestErrorType } from "../../shared/ui/RequestError";

export function AnalyticsError({
    error
}: { error: RequestErrorType }) {
    if ('status' in error && typeof error.status === 'number') {
        const errorData = error.data as ErrorApi;
        const errorCode = errorData.errorCode;

        if (errorCode === 4049) {
            const message = 'Отчет за эту дату не был посчитан';
            return <Error message={message} />
        }
    }

    return <RequestError error={error} />
}