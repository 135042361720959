import { CircularProgress, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";

import { ROWS_PER_PAGE_OPTIONS, usePagination } from "../../../../shared/pagination/usePagination";
import { Deal } from "../../deal.dto";
import { useFetchDeals } from "../../dealAPISlice";
import { DealCard } from "../DealCard";
import { DealFilter, DealFilterParams, useDealFilter } from "./DealFilter";
import { DownloadDealsButton } from "../DownloadDeals";
import { RequestError } from "../../../../shared/ui/RequestError";

export const filterParamsForQuery = (filterParams: Partial<DealFilterParams>) => {
    const queryFilterParams: Partial<DealFilterParams> = {
        ...filterParams
    };
    Object.keys(queryFilterParams).forEach((key) => {
        if (queryFilterParams[key as keyof DealFilterParams] === null) {
            queryFilterParams[key as keyof DealFilterParams] = undefined;
        }
    });
    return queryFilterParams;
}

export const DealListContainer = () => {
    const pagination = usePagination();
    const { filterParams, dispatch } = useDealFilter(() => pagination.changePageNumber(0));

    const { currentData, isFetching, isLoading, error } = useFetchDeals({
        pagination: {
            skip: pagination.firstRowOnPageIndex,
            count: pagination.rowsPerPage
        },
        filterParams: filterParamsForQuery(filterParams)
    });

    if (isLoading) {
        return (
            <div style={{ margin: '200px auto', display: 'flex', justifyContent: 'center' }}>
                <CircularProgress />
            </div>
        )
    }

    return (
        <>
            <DownloadDealsButton params={filterParams} />

            <DealFilter values={filterParams} reducer={dispatch} />

            <TableContainer
                component={Paper}
                sx={{
                    overflowX: 'auto'
                }}
            >
                {
                    isFetching
                        ? <Skeleton variant="rectangular" height={300} />
                        : error
                        ? (<RequestError error={error} />)
                        : <DealListPresenter deals={currentData!} dealsFilter={filterParams} />
                }
                <TablePagination
                    count={-1}
                    page={pagination.currentPageNumber}
                    rowsPerPage={pagination.rowsPerPage}
                    onPageChange={(e, page) => {
                        if (currentData!.length < pagination.rowsPerPage && page > pagination.currentPageNumber) {
                            return;
                        }
                        pagination.changePageNumber(page);
                    }}
                    rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                    onRowsPerPageChange={(e) => { pagination.changeRowsPerPage(e.target?.value as unknown as number) }}

                    style={{
                        'marginLeft': 'auto'
                    }}
                />
            </TableContainer>
        </>
    )
}

export const DealListPresenter = ({ deals, dealsFilter }: { deals: Deal[], dealsFilter?: DealFilterParams }) => {
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Дата</TableCell>
                    <TableCell>Время</TableCell>

                    <TableCell>Инструмент</TableCell>

                    <TableCell>Купил</TableCell>
                    <TableCell>Продал</TableCell>

                    <TableCell>Объем</TableCell>

                    <TableCell>Курс</TableCell>
                    <TableCell>Дата валютирования</TableCell>
                    <TableCell>Стакан</TableCell>

                    <TableCell>Контрагент</TableCell>
                    <TableCell>Курс ЦБ РФ</TableCell>

                    <TableCell>First Rate</TableCell>
                    <TableCell>Комиссия</TableCell>

                    <TableCell>Редактировать</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    deals.map(deal => <DealCard key={`${deal.id}_${deal.buyerCode}_${deal.sellerCode}`} deal={deal} filterParams={dealsFilter} />)
                }
            </TableBody>
        </Table>
    )
}