import { Stack } from "@mui/material";
import { useState } from "react";
import { SelectDay } from "../daily/SelectDay";
import { DownloadCoverReportButton } from "./DownloadCoverReport";
import { useGetCoverReport } from "./coverReportApi.slice";
import { CoverReportTable } from "./CoverReportTable";
import { AnalyticsError } from "../AnalyticsError";

export function CoverReportWidget() {
    const [day, setDay] = useState(new Date().toJSON().split('T')[0]);

    const { currentData, isFetching, error } = useGetCoverReport(day);

    return (
        <div>
            <Stack spacing={4} alignItems={'flex-start'}>

                <Stack direction='row' alignItems='center' spacing={5}>
                    <SelectDay
                        value={day}
                        onChange={setDay}
                    />

                    {
                        error && (
                            <div>
                                <AnalyticsError error={error} />
                            </div>
                        )
                    }
                </Stack>

                <Stack direction='row' alignItems='center' spacing={5}>
                    <DownloadCoverReportButton
                        date={day}
                    />
                </Stack>

                <CoverReportTable
                    date={day}
                    isFetching={isFetching}
                    dailyDealCovererIndicatorsArray={currentData?.dailyDealCovererIndicatorsArray ?? []}

                />
            </Stack>
        </div>
    )
}