import { Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead } from "@mui/material";
import { DailyAnalyticsRow } from "./DailyAnalyticsRow";
import { GetDailyReportResponse } from "./types";

interface DailyAnalyticsTableProps {
    isLoading: boolean;
    dailyDomIndicatorsArray: GetDailyReportResponse['dailyDomIndicatorsArray'];
};

export function DailyAnalyticsTable({
    isLoading,
    dailyDomIndicatorsArray,
}: DailyAnalyticsTableProps) {

    return (
        <TableContainer
            component={Paper}
            sx={{
                overflowX: 'auto'
            }}
        >
            {
                isLoading
                    ? (
                        <Skeleton
                            variant="rectangular"
                            height={300}
                            width={'100%'}
                        />
                    )
                    : (
                        <Table>
                            <TableHead>
                                <TableCell>
                                    Стакан
                                </TableCell>

                                <TableCell>
                                    Комиссия
                                </TableCell>

                                <TableCell>
                                    Инструмент
                                </TableCell>

                                <TableCell>
                                    Объем
                                </TableCell>

                                <TableCell>
                                    Список клиентов
                                </TableCell>
                            </TableHead>

                            <TableBody>
                                {
                                    dailyDomIndicatorsArray.map((item, index) => (
                                        <DailyAnalyticsRow
                                            {...item}
                                            key={index}
                                        />
                                    ))
                                }
                                <caption style={{ width: '100%'}}>
                                    Всего: {dailyDomIndicatorsArray.length}
                                </caption>
                            </TableBody>
                        </Table>
                    )
            }
        </TableContainer>
    )
}