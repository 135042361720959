import { createBrowserRouter } from "react-router-dom";

import { DealFormWidget } from "../widgets/DealFormWidget/DealFormWidget";
import { ClientWidget } from "../widgets/ClientWidget/ClientWidget";
import { ErrorPage } from "../pages/ErrorPage";
import LoginPage from "../features/authentication/ui/LoginPage";
import { AddClientWidget } from "../widgets/AddClientWidget/AddClientWidget";
import { ClientCardWidget } from "../widgets/ClientCardWidget/ClientCardWidget";
import { getClientIdLoader } from "../features/client/ui/EditClientForm";
import { IndexWidget } from "../widgets/IndexWidget/IndexWidget";
import { AuthGateway } from "./withAuthorized/AuthGateway";
import { getDealIDLoader } from "../features/deals/ui/EditDealButton";
import { EditDealWidget } from "../widgets/EditDealWidget/EditDealWidget";
import { SettingsWidget } from "../widgets/Settings/SettingsWidget";
import { DealListContainer } from "../features/deals/ui/DealList/DealList";
import { MainApp } from "../features/layout/MainAppLayout";
import { CommissionWidget } from "../features/commissions/CommissionWidget";
import { InvoicesWidget } from "../features/invoices/InvoicesWidget";
import { getInvoiceIdLoader, InvoiceCardWidget } from "../features/invoices/InvoiceCard";
import { AnalyticsWidget } from "../features/analytics/AnalyticsWidget";


export const appRouter = createBrowserRouter([
    {
        path: '/login',
        element: <LoginPage />,
        errorElement: <ErrorPage />
    },
    {
        path: '/',
        element: <AuthGateway>
            <MainApp />
        </AuthGateway>,
        errorElement: <ErrorPage />,
        children: [
            {
                index: true,
                element: <IndexWidget />,
            },
            {
                path: 'dealform',
                element: <DealFormWidget />,
                errorElement: <ErrorPage />
            },
            {
                path: 'clients',
                element: <ClientWidget />,
                errorElement: <ErrorPage />
            },
            {
                path: 'clients/:clientId',
                element: <ClientCardWidget />,
                loader: getClientIdLoader,
                errorElement: <ErrorPage />
            },
            {
                path: 'deals',
                element: <DealListContainer />,
                errorElement: <ErrorPage />
            },
            {
                path: 'deals/:dealId',
                element: <EditDealWidget />,
                loader: getDealIDLoader,
                errorElement: <ErrorPage />
            },
            {
                path: 'addNewClient',
                element: <AddClientWidget />,
                errorElement: <ErrorPage />
            },
            {
                path: 'settings',
                element: <SettingsWidget />,
                errorElement: <ErrorPage />
            },
            {
                path: 'commissions',
                element: <CommissionWidget />,
                errorElement: <ErrorPage />
            },
            {
                path: 'invoices',
                element: <InvoicesWidget />,
                errorElement: <ErrorPage />
            },
            {
                path: 'invoices/:invoiceId',
                element: <InvoiceCardWidget />,
                loader: getInvoiceIdLoader,
                errorElement: <ErrorPage />
            },
            {
                path: 'report',
                element: <AnalyticsWidget />,
                errorElement: <ErrorPage />
            },
            {
                path: '*',
                element: <p>Page Not Found</p>
            }
        ]
    }
])