import { LoadingButton } from "@mui/lab";
import DownloadIcon from '@mui/icons-material/Download';
import { useDownloadCoverReport } from "./coverReportApi.slice";
import { useEffect, useRef, useState } from "react";
import { Stack } from "@mui/material";
import { RequestError } from "../../../shared/ui/RequestError";

interface DownloadCoverReportButtonProps {
    date: string;
}

export function DownloadCoverReportButton({
    date,
}: DownloadCoverReportButtonProps) {
    const [downloadReport, metadata] = useDownloadCoverReport();

    const [url, setUrl] = useState('');
    const [filename, setFilename] = useState('');
    const aRef = useRef<HTMLAnchorElement>(null);

    useEffect(() => {
        if (url && filename && aRef.current) {
            aRef.current?.click();
        }
    }, [url, filename]);

    return (
        <Stack direction='row' alignItems='center' spacing={6}>
            <LoadingButton
                variant="contained"
                startIcon={<DownloadIcon />}
                loading={metadata.isLoading}
                onClick={async () => {
                    const response = await downloadReport(date);
                    if ('data' in response) {
                        const newUrl = window.URL.createObjectURL(response.data);
                        setUrl(newUrl);
                        setFilename(`CoverReport-${date}.xlsx`);
                        window.URL.revokeObjectURL(url);
                    }
                }}
            >
                Скачать
            </LoadingButton>

            <a
                ref={aRef}
                style={{
                    display: 'none'
                }}
                href={url}
                download={filename}
            />

            {
                metadata.error && (
                    <div style={{ width: '700px' }}>
                        <RequestError error={metadata.error} />
                    </div>
                )
            }
        </Stack>
    )
}