import 'dayjs/locale/ru.js';
import { useReducer } from 'react';
import { Button, ButtonGroup, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Stack } from '@mui/material';
import { Counterparty, Instrument } from '../../deal.dto';
import { InstrumentInput } from '../DealInputs/InstrumentInput';
import { ClientCodeInput } from '../../../client/ui/ClientInput';
import './DealFilter.css';
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/ru.js';


export interface DealFilterParams {
    From: string | null,
    To: string | null,
    Instrument: Instrument | string | null,
    BuyerCode: string | null,
    SellerCode: string | null,
    Counterparty: Counterparty.line | Counterparty.moex | 'Default',
    ClientCode: string | null
}

function getToday() {
    const today = new Date(Date.now());
    today.setHours(0, 0, 0, 0);
    return today;
}

function getTomorrow() {
    const today = getToday();
    today.setDate(today.getDate() + 1);
    return today;
}

const initialValues: DealFilterParams = {
    From: getToday().toJSON(),
    To: getTomorrow().toJSON(),
    Instrument: null,
    BuyerCode: null,
    SellerCode: null,
    Counterparty: 'Default',
    ClientCode: null
}

export function useDealFilter(onFilterChange?: () => void) {
    const [filterParams, dispatch] = useReducer(function (state: DealFilterParams, action: Partial<DealFilterParams>) {
        onFilterChange?.();
        return {
            ...state,
            ...action
        }
    }, initialValues);

    return { filterParams, dispatch };
}

export function DealFilter({
    values, reducer,
}: {
    values: DealFilterParams, 
    reducer: (params: Partial<DealFilterParams>) => void,
}) {

    const handleSelectChange = (event: SelectChangeEvent) => {
        if (event.target.value === Counterparty.line) {
            reducer({ Counterparty: Counterparty.line });

        } else if (event.target.value === Counterparty.moex) {
            reducer({ Counterparty: Counterparty.moex });
        } else {
            reducer({ Counterparty: 'Default' })
        }
    };

    return (
        <form style={{ width: '100%' }}>

            <ButtonGroup
                sx={{
                    alignSelf: 'center',
                    margin: '20px 0 0 0'
                }}
            >
                <Button
                    onClick={() => {
                        const newStartDate = new Date();
                        newStartDate.setHours(0, 0, 0, 0);
                        newStartDate.setDate(1);

                        const newEndDate = new Date();
                        newEndDate.setMonth(newEndDate.getMonth() + 1);
                        newEndDate.setHours(23, 59, 59, 999);
                        newEndDate.setDate(0);

                        reducer({ From: newStartDate.toJSON(), To: newEndDate.toJSON() });
                    }}
                >
                    За текущий месяц
                </Button>
                <Button
                    onClick={() => {
                        const newStartDate = new Date();
                        newStartDate.setHours(0, 0, 0, 0);
                        newStartDate.setDate(1);
                        newStartDate.setMonth(newStartDate.getMonth() - 1);

                        const newEndDate = new Date();
                        newEndDate.setHours(23, 59, 59, 999);
                        newEndDate.setDate(0);

                        reducer({ From: newStartDate.toJSON(), To: newEndDate.toJSON() });
                    }}
                >
                    За прошлый месяц
                </Button>
            </ButtonGroup>

            <div className="deal-filter">
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
                    <div>
                        <DateTimePicker
                            className="Input"
                            name="dateTime"
                            label="Начало"
                            value={dayjs(values.From ? new Date(values.From) : new Date())}
                            onChange={newValue => {
                                reducer({ From: newValue?.toJSON() });
                            }}
                        />
                    </div>
                    <div>
                        <DateTimePicker
                            className="Input"
                            name="dateTime"
                            label="Конец"
                            value={dayjs(values.To ? new Date(values.To) : new Date())}
                            onChange={newValue => {
                                reducer({ To: newValue?.toJSON() });
                            }}
                        />
                    </div>
                </LocalizationProvider>
            </div>

            <div className="deal-filter">
                <InstrumentInput
                    className="Input"
                    id="instrument"
                    name="instrument"
                    placeholder="Инструмент"
                    label="Инструмент"
                    value={values.Instrument}
                    onChange={(event: React.SyntheticEvent<Element, Event>, value: Instrument) => {
                        reducer({ Instrument: value?.instrument ?? null })
                    }}
                />

                <Stack direction='row' spacing={1}>
                    <ClientCodeInput
                        className="Input"
                        id="buyerCode"
                        name="buyerCode"
                        placeholder="Купил"
                        label="Купил"
                        value={values.BuyerCode}
                        onChange={(value) => {
                            reducer({ BuyerCode: value ?? null, ClientCode: null })
                        }}
                    />

                    <ClientCodeInput
                        className="Input"
                        id="sellerCode"
                        name="sellerCode"
                        placeholder="Продал"
                        label="Продал"
                        value={values.SellerCode}
                        onChange={(value) => {
                            reducer({ SellerCode: value ?? null, ClientCode: null })
                        }}
                    />

                    <ClientCodeInput
                        className="Input"
                        id="ClientCode"
                        name="ClientCode"
                        placeholder="Клиент"
                        label="Клиент"
                        value={values.ClientCode}
                        onChange={(value) => {
                            reducer({ ClientCode: value ?? null, SellerCode: null, BuyerCode: null })
                        }}
                    />
                </Stack>

                <FormControl>
                    <InputLabel>Клиринг</InputLabel>

                    <Select
                        className="Input"
                        id="counterparty"
                        name="counterparty"
                        label="Клиринг"
                        value={values.Counterparty}
                        onChange={handleSelectChange}
                    >
                        <MenuItem value="Default">
                            Все
                        </MenuItem>
                        <MenuItem value={Counterparty.line}>
                            {Counterparty.line}
                        </MenuItem>
                        <MenuItem value={Counterparty.moex}>
                            {Counterparty.moex}
                        </MenuItem>
                    </Select>
                </FormControl>
            </div>
        </form>
    )
}
