import { Tabs, Tab } from "@mui/material";
import { useState } from "react";
import { DailyAnalyticsWidget } from "./daily/DailyAnalyticsWidget";
import { CoverReportWidget } from "./report/CoverReportWidget";

enum AnalyticsWidgetTabsEnum {
    dailyLightReport = 0,
    coverReport = 1,
}

export function AnalyticsWidget() {
    const [tab, setTab] = useState<AnalyticsWidgetTabsEnum>(AnalyticsWidgetTabsEnum.dailyLightReport);

    return (
        <>
            <h2>Отчеты</h2>
            <Tabs value={tab} onChange={(e, newValue) => setTab(newValue)} sx={{ marginBottom: '30px' }}>
                <Tab label="Ежедневный отчет" />
                <Tab label="Отчет по перекрытиям" />
            </Tabs>
            {
                tab === AnalyticsWidgetTabsEnum.dailyLightReport && (
                    <DailyAnalyticsWidget />
                )
            }
            {
                tab === AnalyticsWidgetTabsEnum.coverReport && (
                    <CoverReportWidget />
                )
            }
        </>
    )
}