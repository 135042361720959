import { getDateFormat } from "../../../../shared/helpers/timeFormat";
import { DailyDomIndicators, GetDailyReportResponse } from "../types";

export function convertReportToText(data: GetDailyReportResponse) {
    const title = getTitle(data.date);

    const doms = data.dailyDomIndicatorsArray
        .map(dom => convertDomToText(dom))
        .join('\n\n');

    const total = getTotal(data.commissionDailyAggregated, data.commissionMonthlyAggregated);

    return [title, doms, total].join('\n\n');
}

function getTitle(dateStr: string) {
    const date = new Date(dateStr);
    const dateFormatString = getDateFormat(date);
    return `Сделки за ${dateFormatString}`;
}

function getTotal(dayTotal: number, monthlyTotal: number) {
    const dayTotalString = `Комиссия за день: ${dayTotal.toLocaleString('ru')} руб.`;
    const monthTotalString = `Комиссия за месяц: ${monthlyTotal.toLocaleString('ru')} руб.`;

    return [dayTotalString, monthTotalString].join('\n');
}

function convertDomToText(dom: DailyDomIndicators) {
    const firstLine = `${dom.dom}:`;

    const instruments = dom.dailyInstrumentIndicatorsArray
        .map((instrument) => {
            const clients = instrument.dailyClientIndicatorsArray?.map(client => client.clientCode).join(', ');
            const value = instrument.valueDailyAggregated.toLocaleString('ru');
            return `- ${instrument.instrument}:  ${value} руб. (${clients})`;
        });

    const commissionLine = `Комиссия: ${dom.commissionDailyAggregated} руб.`;

    return [
        firstLine,
        ...instruments,
        commissionLine,
    ].join('\n');
}