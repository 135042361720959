import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/ru.js';

interface SelectDayProps {
    value: string;
    onChange: (newDay: string) => void;
}

export function SelectDay({
    value,
    onChange,
}: SelectDayProps) {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
            <DatePicker
                value={dayjs(value)}
                onChange={newValue => {
                    const newDate = newValue?.format('YYYY-MM-DD') ?? '';
                    onChange(newDate);
                }}
                disableFuture
            />
        </LocalizationProvider>
    )
}