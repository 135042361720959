import { TableCell, TableRow } from "@mui/material";
import { DailyDomIndicators } from "./types";

export function DailyAnalyticsRow(data: DailyDomIndicators) {
    const firstRow = data.dailyInstrumentIndicatorsArray.at(0);
    const others = data.dailyInstrumentIndicatorsArray.slice(1);

    const rowSpan = data.dailyInstrumentIndicatorsArray.length > 0
        ? data.dailyInstrumentIndicatorsArray.length
        : undefined;

    return (
        <>
            <TableRow>
                <TableCell rowSpan={rowSpan}>
                    {data.dom}
                </TableCell>

                <TableCell rowSpan={rowSpan}>
                    {data.commissionDailyAggregated.toLocaleString('ru')} руб
                </TableCell>

                <TableCell>
                    {firstRow?.instrument ?? ''}
                </TableCell>

                <TableCell>
                    {firstRow?.valueDailyAggregated.toLocaleString('ru') ?? ''}
                </TableCell>

                <TableCell>
                    {firstRow?.dailyClientIndicatorsArray?.map(client => client.clientCode).join(', ') ?? ''}
                </TableCell>
            </TableRow>

            {
                others.map((item) => (
                    <TableRow key={item.instrument}>
                        <TableCell>
                            {item.instrument}
                        </TableCell>

                        <TableCell>
                            {item.valueDailyAggregated.toLocaleString('ru')}
                        </TableCell>

                        <TableCell>
                            {item.dailyClientIndicatorsArray?.map(client => client.clientCode).join(', ')}
                        </TableCell>
                    </TableRow>
                ))
            }
        </>
    )
}