import { CircularProgress, Typography } from "@mui/material";
import { RequestErrorType } from "../../../shared/ui/RequestError";
import { getDateFormat, getTimeFormat } from "../../../shared/helpers/timeFormat";
import { AnalyticsError } from "../AnalyticsError";

interface ReportCalculatedInfoProps {
    isLoading: boolean;
    calculatedAt?: string;
    error?: RequestErrorType;
}

export function ReportCalculatedInfo({
    isLoading,
    calculatedAt,
    error,
}: ReportCalculatedInfoProps) {
    if (isLoading) {
        return <CircularProgress />
    }

    if (calculatedAt) {
        return (
            <Typography>
                Рассчитано {getDateFormat(new Date(calculatedAt))} {getTimeFormat(new Date(calculatedAt))}
            </Typography>
        )
    }

    if (error) {
        return <div><AnalyticsError error={error} /></div>
    }

    return <></>
}