import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from '../../../api/apiHelpers';
import { GetCoverReportResponse } from './types';

export const coverReportAPI = createApi({
  reducerPath: 'coverReportAPI',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({

    downloadCoverReport: builder.mutation<File, string>({
      query: (date: string) => {
        return {
          url: `reports/coverers-report/excel?date=${date}`,
          responseHandler: async (response) => {
            if (response.ok) {
                const blob = await response.blob();
                const file = new File(
                    [blob], 
                    'cover-report.xlsx', 
                    { type: 'octet/stream' }
                );
                return file;
            } else {
                return response.json();
            }
        },
        }
      }
    }),

    getCoverReport: builder.query<GetCoverReportResponse, string>({
      query: (date: string) => ({
        url: `reports/coverers-report?date=${date}`
      })
    })

  })
});

export const useDownloadCoverReport = coverReportAPI.endpoints.downloadCoverReport.useMutation;
export const useGetCoverReport = coverReportAPI.endpoints.getCoverReport.useQuery;