import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from '../../../api/apiHelpers';
import { GetDailyReportResponse } from './types';

export const dailyAnalyticsAPI = createApi({
  reducerPath: 'dailyAnalyticsAPI',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({

    getDailyLightReport: builder.query<GetDailyReportResponse, string>({
      query: (date: string) => {
        return {
          url: `reports/light-report?date=${date}`,
        }
      }
    })

  })
});

export const useGetDailyLightReport = dailyAnalyticsAPI.endpoints.getDailyLightReport.useQuery;