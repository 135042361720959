import { Stack, TableCell, TableRow } from "@mui/material";
import { DailyDealCovererIndicators } from "./types";
import { getDateFormat } from "../../../shared/helpers/timeFormat";
import { useMemo } from "react";

interface CoverReportRowProps {
    date: string;
    instrument: string;
    clientCodes: string[];
    indicators: DailyDealCovererIndicators[];
}

export function CoverReportRow({
    date,
    instrument,
    clientCodes,
    indicators,
}: CoverReportRowProps) {
    const total = useMemo(() => {
        return indicators.reduce((acc, cur) => acc += cur.value, 0);
    }, [indicators]);

    const clientCodeCover = useMemo(() => {
        return clientCodes.map((client) => {
            const clientIndicator = indicators.find(indicator => indicator.clientCode === client);
            return clientIndicator
                ? (
                    <TableCell align="center">
                        <Stack direction={'row'} spacing={2}>
                            <div>{clientIndicator.value.toLocaleString('ru')}</div>
                            <div style={{ textWrap: 'nowrap' }}>{`${clientIndicator.valuePercent.toFixed(2)} %`}</div>
                        </Stack>
                    </TableCell>
                )
                : (<TableCell></TableCell>);
        })
    }, [clientCodes, indicators]);

    return (
        <TableRow hover>
            <TableCell scope="row">
                {getDateFormat(new Date(date))}
            </TableCell>

            <TableCell sx={{ width: '300px' }}>
                {instrument}
            </TableCell>

            <TableCell sx={{ width: '300px' }}>
                {total.toLocaleString('ru')}
            </TableCell>

            {
                clientCodeCover.map((item) => item)
            }
        </TableRow>
    )
}