import { Skeleton, Stack } from "@mui/material";

interface DailyTotalProps {
    isLoading: boolean;
    commissionDailyAggregated?: number;
    commissionMonthlyAggregated?: number;
}

export function DailyTotal({
    isLoading,
    commissionDailyAggregated,
    commissionMonthlyAggregated,
}: DailyTotalProps) {
    if (isLoading) {
        return <Skeleton />
    }

    if (commissionDailyAggregated === undefined
        && commissionMonthlyAggregated === undefined
    ) {
        return <></>
    }

    return (
        <Stack spacing={'20'}>
            <p>{`Комиссия: ${commissionDailyAggregated!.toLocaleString('ru')} руб.`}</p>
            
            <p>{`Комиссия за месяц: ${commissionMonthlyAggregated!.toLocaleString('ru')} руб.`}</p>
        </Stack>
    )
}